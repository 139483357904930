

































import { Vue, Component, Prop } from "vue-property-decorator";
import { Dialog } from "element-ui";
@Component({
    components: {
        "el-dialog": Dialog
    }
})
export default class LoginTypeDialog extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: () => {
            return false;
        }
    })
    isShow: any;

    handleDialog() {
        this.$emit("update:isShow", false);
    }

    handelRegistType(type: string) {
        let routeOpt: {};
        switch (type) {
            case "project":
                routeOpt = { name: "login" };
                break;
            case "expert":
                routeOpt = { name: "login-expert"};
                break;
            default:
                routeOpt = false;
                break;
        }
        this.$emit("update:isShow", false);
        if (routeOpt) {
            this.$router.push(routeOpt);
        }
    }
}
