






























































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Dropdown, DropdownMenu, DropdownItem } from "element-ui";
import AlButton from "@/components/al/Button.vue";
import LoginTypeDialog from "@/components/sign/LoginTypeDialog.vue";

@Component({
    components: {
        "el-dropdown": Dropdown,
        "el-dropdown-menu": DropdownMenu,
        "el-dropdown-item": DropdownItem,
        "al-button": AlButton,
        LoginTypeDialog,
    },
})
export default class Navbar extends Vue {
    @Getter("getToken") token: any;
    @Action("getSetToken") setToken: any;
    @Getter("getLang") lang: any;
    @Action("getSetLang") setLangCommit: any;
    @Getter("getUser") getUser: any; //获取store中的user的方法
    @Getter("getAssessPermission") assessPermission: any; //获取store中的user的方法
    @Action("getSetAssessPermission") setAssessPermission: any; //设置store评审权限
    @Action("clear") clear: any;

    title = "全球百佳技术转移案例";
    loginButton: any = true; //未登录状态下是否显示登录按钮
    registButton: any = true; //未登录状态下是否显示注册按钮
    dropdownStyleTop: any = ""; //下来菜单top

    registDialog: boolean = false; //是否显示注册种类选择弹窗

    showAssess: boolean = false; //下来菜单是否显示评审

    get user() {
        if (this.token) return this.getUser();
        else return {};
    }

    /* 修改语言 */
    changeLang() {
        if (this.lang == "zh") {
            sessionStorage.setItem("lang", "en");
            this.setLangCommit("en");
        } else if (this.lang == "en") {
            sessionStorage.setItem("lang", "zh");
            this.setLangCommit("zh");
        } else {
            sessionStorage.setItem("lang", "zh");
            this.setLangCommit("zh");
        }
        let params = JSON.parse(JSON.stringify(this.$route.params));
        let name = JSON.parse(JSON.stringify(this.$route.name));
        params.lang = this.lang;
        // this.$router.push({ name: name, params: params });

        // console.log(`当前语言为：${this.lang}`);
    }

    /* 修改语言按钮的文本 */
    langmsg() {
        if (this.lang == "zh") {
            return "Eng";
        } else if (this.lang == "en") {
            return "中";
        } else {
            return "中";
        }
    }

    //去登录
    goSubPage() {
        this.$router.push({
            name: "login-expert"
        });
        // this.registDialog = true; //打开专家与项目注册选择弹窗
    }

    // 去注册
    goRegisterPage() {
        this.$router.push({
            name: "register",
            params: { step: "0" },
        });
        // this.registDialog = true; //打开专家与项目注册选择弹窗
    }

    /**登出 */
    logout() {
        //注销
        sessionStorage.clear();
        this.setToken("");
        this.$router.push({ name: "home" });
        this.clear();
    }

    goUserHome(name?: any) {
        if (name) {
            if (name == "register") {
                this.$router.push({
                    name: name,
                    params: { step: "1" },
                });
            } else {
                this.$router.push({ name: name });
            }
        } else {
            this.$router.push({ path: "/" });
        }
    }

    // 点击注册按钮下拉菜单项
    handleRegistDropdownCommand(command: any) {
        if (command == "projectRegist") {
            this.$router.push({
                name: "register",
                params: { step: "1" },
            });
        }
    }

    //点击个人中心下拉菜单项
    handleUserDropdownCommand(command: any) {
        if (command == "logout") {
            this.logout();
        } else if (command == "assessList") {
            this.$router.push({
                name: command,
                params: {
                    eid: this.user.expert_id,
                    id: this.assessPermission[0].review_id,
                },
            });
        } else {
            this.goUserHome(command);
        }
    }

    @Watch("$route")
    routeChange(to: any, from: any) {
        // console.log(from)
        if (to.name == "login") {
            this.loginButton = false;
            this.registButton = true;
        } else if (to.name == "register") {
            this.loginButton = true;
            this.registButton = true;
        } else {
            this.loginButton = true;
            this.registButton = true;
        }
    }

    @Watch("user")
    userChange(nd: any, od: any) {
        this.getAssessPermission();
    }

    /**获取评审权限 */
    getAssessPermission() {
        const eid = this.user.expert_id;
        if (eid) {
            if (!this.assessPermission || this.assessPermission.length <= 0) {
                const getOpt = this.$api.apiList.getAssessPermission;
                const opt = getOpt(eid);
                this.$axios({
                    url: opt.url,
                    method: opt.method,
                    headers: opt.headers,
                })
                    .then((res: any) => {
                        this.setAssessPermission(res.data.data);
                        if (res.data.data.length > 0) {
                            this.showAssess = true;
                        } else {
                            this.showAssess = false;
                        }
                    })
                    .catch((err: any) => {
                        console.log(err);
                        this.$notify({
                            title: "警告",
                            message: "获取评审权限失败",
                            type: "warning",
                        });
                    });
            } else {
                this.showAssess = true;
            }
        } else {
            this.showAssess = false;
        }
    }

    mounted() {
        this.langmsg();
        if (this.$route.name == "login") {
            this.registButton = true;
            this.loginButton = false;
        } else if (this.$route.name == "register") {
            this.registButton = true;
            this.loginButton = true;
        } else {
            this.loginButton = true;
            this.registButton = true;
        }
        this.getAssessPermission();
        // console.log(JSON.stringify(this.test));

    }

    // test = [
    //     {
    //         formkey: "judge1",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "政策契合（该案例与我国创新驱动发展、促进科技成果转移转化等政策方针高度契合，积极响应了当前中国科协、科技部等国家部委，以及中央及地方政府的重点工作）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: [
    //                     { content: { en: "", zh_CN: "优秀" }, score: 3 },
    //                     { content: { en: "", zh_CN: "良好" }, score: 2 },
    //                     { content: { en: "", zh_CN: "一般" }, score: 1 },
    //                 ],
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "judge2",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "特色鲜明（该案例在国际技术转移、技术交易、科技成果转化等方面采用了具有创新型、开拓性的工作模式与实施方法，形成了鲜明的特征特色与工作特点）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: [
    //                     { content: { en: "", zh_CN: "优秀" }, score: 3 },
    //                     { content: { en: "", zh_CN: "良好" }, score: 2 },
    //                     { content: { en: "", zh_CN: "一般" }, score: 1 },
    //                 ],
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "judge3",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "示范意义（该案例通过其突出的社会与行业影响力，或依托全球影响力机构或重大项目等，为开展国际技术转移工作提供参考和借鉴，形成了典型示范作用）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: [
    //                     { content: { en: "", zh_CN: "优秀" }, score: 3 },
    //                     { content: { en: "", zh_CN: "良好" }, score: 2 },
    //                     { content: { en: "", zh_CN: "一般" }, score: 1 },
    //                 ],
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "judge4",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "务实成效（该案例在社会价值、经济价值等方面带来了丰富成果，解决了科技创新发展中的实际问题，为依托机构或所在行业、区域、经济体等取得显著成效）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: [
    //                     { content: { en: "", zh_CN: "优秀" }, score: 3 },
    //                     { content: { en: "", zh_CN: "良好" }, score: 2 },
    //                     { content: { en: "", zh_CN: "一般" }, score: 1 },
    //                 ],
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "judge5",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "攻坚克难（该案例取得了在相应科技研究领域、国家和区域发展所需的关键核心技术等方面的重大突破）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: [
    //                     { content: { en: "", zh_CN: "优秀" }, score: 3 },
    //                     { content: { en: "", zh_CN: "良好" }, score: 2 },
    //                     { content: { en: "", zh_CN: "一般" }, score: 1 },
    //                 ],
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "judge6",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "开放合作（该案例是典型的跨境合作项目，或在促进开放创新与国际合作等方面发挥重要作用）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: [
    //                     { content: { en: "", zh_CN: "优秀" }, score: 3 },
    //                     { content: { en: "", zh_CN: "良好" }, score: 2 },
    //                     { content: { en: "", zh_CN: "一般" }, score: 1 },
    //                 ],
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "judge7",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "信息详实（该案例所提供和组织的信息充分、重点突出、内容详实）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //        children: [
    //             {
    //                 opt: [
    //                     { content: { en: "", zh_CN: "优秀" }, score: 3 },
    //                     { content: { en: "", zh_CN: "良好" }, score: 2 },
    //                     { content: { en: "", zh_CN: "一般" }, score: 1 },
    //                 ],
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "comment",
    //         type: "input",
    //         label: { en: "", zh_CN: "点评意见" },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         children: [],
    //     },
    // ];

    // test_1 = [
    //     {
    //         formkey: "judge1",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "政策契合（该案例与我国创新驱动发展、促进科技成果转移转化等政策方针高度契合，积极响应了当前中国科协、科技部等国家部委，以及中央及地方政府的重点工作）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: {
    //                     "3": { en: "", zh_CN: "优秀" },
    //                     "2": { en: "", zh_CN: "良好" },
    //                     "1": { en: "", zh_CN: "一般" },
    //                 },
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "judge2",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "特色鲜明（该案例在国际技术转移、技术交易、科技成果转化等方面采用了具有创新型、开拓性的工作模式与实施方法，形成了鲜明的特征特色与工作特点）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: {
    //                     "3": { en: "", zh_CN: "优秀" },
    //                     "2": { en: "", zh_CN: "良好" },
    //                     "1": { en: "", zh_CN: "一般" },
    //                 },
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "judge3",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "示范意义（该案例通过其突出的社会与行业影响力，或依托全球影响力机构或重大项目等，为开展国际技术转移工作提供参考和借鉴，形成了典型示范作用）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: {
    //                     "3": { en: "", zh_CN: "优秀" },
    //                     "2": { en: "", zh_CN: "良好" },
    //                     "1": { en: "", zh_CN: "一般" },
    //                 },
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "judge4",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "务实成效（该案例在社会价值、经济价值等方面带来了丰富成果，解决了科技创新发展中的实际问题，为依托机构或所在行业、区域、经济体等取得显著成效）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: {
    //                     "3": { en: "", zh_CN: "优秀" },
    //                     "2": { en: "", zh_CN: "良好" },
    //                     "1": { en: "", zh_CN: "一般" },
    //                 },
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "judge5",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "攻坚克难（该案例取得了在相应科技研究领域、国家和区域发展所需的关键核心技术等方面的重大突破）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: {
    //                     "3": { en: "", zh_CN: "优秀" },
    //                     "2": { en: "", zh_CN: "良好" },
    //                     "1": { en: "", zh_CN: "一般" },
    //                 },
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "judge6",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "开放合作（该案例是典型的跨境合作项目，或在促进开放创新与国际合作等方面发挥重要作用）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: {
    //                     "3": { en: "", zh_CN: "优秀" },
    //                     "2": { en: "", zh_CN: "良好" },
    //                     "1": { en: "", zh_CN: "一般" },
    //                 },
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "judge7",
    //         type: "radio",
    //         label: {
    //             en: "",
    //             zh_CN: "信息详实（该案例所提供和组织的信息充分、重点突出、内容详实）",
    //         },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         rules: [
    //             { required: true, message: "评分选项不为空", trigger: "blur" },
    //         ],
    //         children: [
    //             {
    //                 opt: {
    //                     "3": { en: "", zh_CN: "优秀" },
    //                     "2": { en: "", zh_CN: "良好" },
    //                     "1": { en: "", zh_CN: "一般" },
    //                 },
    //             },
    //         ],
    //     },
    //     {
    //         formkey: "comment",
    //         type: "input",
    //         label: { en: "", zh_CN: "点评意见" },
    //         placeholder: "",
    //         example: "",
    //         value: "",
    //         children: [],
    //     },
    // ];
}
